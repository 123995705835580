.form {
    --radius: calc(var(--border-radius) / 2);
    --shadow-radius: var(--box-shadow-radius);

    align-items: center;
    background: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.input {
    padding-bottom: 20px;
}

.input label {
    display: none;
}

.input input {
    background: #fff !important;
    border: 1px solid var(--box-shadows);
    border-radius: 4px;
    color: var(--teal);
    font-size: 16rem;
    font-weight: bold;
    height: 36px;
    margin-top: 4px;
    outline: none;
    padding-left: 8px;
    width: 100%;
}

.input input::placeholder {
    color: #777;
}

.dual-input {
    display: flex;
    gap: 26px;
    width: 100%;
}

@media screen and (max-width: 1050px) {
    .input input::placeholder {
        color: var(--black);
    }
}

@media screen and (min-width: 1051px) {
    .form {
        border-radius: 0 var(--radius) var(--radius) var(--radius);
        box-shadow: 0 0 var(--shadow-radius) var(--box-shadows);
        flex-direction: row;
    }

    .input {
        padding: 20px;
    }

    .input {
        border-right: 1px solid var(--box-shadows);
    }

    .input input {
        border: none;
        font-size: 15rem;
        font-weight: normal;
        padding-left: 0;
    }

    .input label {
        color: var(--black);
        display: block;
        font-weight: bold;
    }
}