.baner {
    display: block;
    text-align: center;
}

.baner img {
    object-fit: contain;
    width: 100%;
}

.mobile {
    margin-inline: calc(-1 * var(--container-padding));
}

.mobile img {
    width: calc(100% + 2 * var(--container-padding));
}