.wrapper {
    background-color: var(--gray);
    max-width: 1600px;
    position: relative;
    width: 100%;
    z-index: 0;
}

@media screen and (min-width: 1025px) {
    .grid {
        align-items: center;
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(3, 1fr);
    }

    .wrapper {
        height: 400px;
    }
}